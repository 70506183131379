const API_BASE_URL = process.env.REACT_APP_API_URL
const API_VERSION = ''
// const VERSION_URL = `${API_BASE_URL}/${API_VERSION}`
export const VERSION_URL = API_BASE_URL

// auth service urls
export const LOGIN = '/user/login'
export const REGISTER = '/user/register'
export const GET_USER_PROFILE = '/user/me'
export const CREATE_COMPANY = '/company'
export const UPDATE_USER = '/user/self'
export const UPDATE_COMPANY = '/company/self'

// company service urls
export const GET_ALL_CLIENT_COMPANIES = '/company'
export const UPDATE_PO_ENABLED = '/company/admin/po-enabled';

// machine shop service urls
export const CREATE_MACHINE_SHOP = '/machine-shop'
export const UPDATE_MACHINE_SHOP = '/machine-shop'
export const MACHINE_SHOP_CAPABILITY_LIST = '/machine-shop-capability/list'
export const MACHINE_SHOP_CERTIFICATION_LIST = '/machine-shop-certification/list'
export const GET_ADDRESS = '/address'
export const BANK_CONNECT = '/stripe/connect'
export const BANK_DISCONNECT = '/stripe/disconnect'
export const GET_MACHINE_SHOP_STATUS = '/machine-shop/application/status'

// part service urls
export const CREATE_PART = '/part'
export const GET_FILE = '/file-upload'

// part request service urls
export const CREATE_PART_REQUEST = '/part-request'
export const PROJECT_PART_REQUEST = '/project'
export const PART_BID_REQUEST = '/part-bid'

// project service urls
export const CREATE_PROJECT = '/project'

// quote service urls
export const CREATE_QUOTE = '/quote-request'
export const GET_QUOTES = '/project'

// shop service urls
export const CREATE_SHOP = '/machine-shop'

// cart service urls
export const GET_CART = '/cart'
export const ADMIN_APPROVE_PO = '/cart/admin/approve'
export const APPROVE_PO = '/cart/approve'
export const CREATE_PURCHASE_ORDER = '/payment'
export const GET_ALL_PAYMENTS = '/payment/admin'

// stripe service urls
export const CREATE_STRIPE = '/stripe'
export const CREATE_CHECKOUT_SESSION = '/stripe/checkout-session'

// conversation service urls
export const GET_CONVERSATIONS = '/conversation'

// help service urls
export const CREATE_HELP = '/support/email'
