import _ from 'lodash'

import * as ActionTypes from '../utils/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  clientCompanies: [],
  addresses: [],
  filters: [],
  filters_2: [],
}

const reducer = (state = initialState, action) => {
  let newState = _.cloneDeep(state)

  switch (action.type) {
    case ActionTypes.COMPANY_API_LOADING:
      newState.isLoading = true
      return newState

    case ActionTypes.COMPANY_API_ERROR:
      newState.isLoading = false
      return newState

    case ActionTypes.SUCCESS_GET_CLIENT_COMPANIES:
      newState.isLoading = false
      newState.clientCompanies = action.payload
      return newState

    case ActionTypes.SUCCESS_UPDATE_PO_ENABLED:
      newState.isLoading = false
      newState.clientCompanies = newState.clientCompanies.map((company) =>
        company.id === action.payload.id
          ? { ...company, poEnabled: action.payload.poEnabled }
          : company
      )
      return newState

    default:
      return state
  }
}

export default reducer