// auth action types
export const AUTH_API_LOADING = '@AUTH/AUTH_API_LOADING'
export const AUTH_API_ERROR = '@AUTH/AUTH_API_ERROR'
export const AUTH_LOGIN_SUCCESS = '@AUTH/AUTH_LOGIN_SUCCESS'
export const AUTH_RESET_PASSWORD_SUCCESS = '@AUTH/AUTH_RESET_PASSWORD_SUCCESS'
export const AUTH_REGISTER_SUCCESS = '@AUTH/AUTH_REGISTER_SUCCESS'
export const LOG_OUT = '@AUTH/LOG_OUT'
export const SUCCESS_GET_USER = '@AUTH/SUCCESS_GET_USER'
export const SUCCESS_GET_COMPANY = '@AUTH/SUCCESS_GET_COMPANY'
export const SUCCESS_CREATE_COMPANY = '@AUTH/SUCCESS_CREATE_COMPANY'
export const SUCCESS_UPDATE_USER = '@AUTH/SUCCESS_UPDATE_USER'
export const SUCCESS_UPDATE_COMPANY = '@AUTH/SUCCESS_UPDATE_COMPANY'
export const SUCCESS_GET_CLIENT_ADDRESS = '@AUTH/SUCCESS_GET_CLIENT_ADDRESS'
export const PASS_RIDE = '@AUTH/PASS_RIDE'

// company action types
export const SUCCESS_GET_CLIENT_COMPANIES = '@COMPANY/SUCCESS_GET_CLIENT_COMPANIES'
export const COMPANY_API_LOADING = '@COMPANY/COMPANY_API_LOADING'
export const COMPANY_API_ERROR = '@COMPANY/COMPANY_API_ERROR'
export const SUCCESS_UPDATE_PO_ENABLED = 'SUCCESS_UPDATE_PO_ENABLED';

// machine shop action types
export const MACHINE_SHOP_API_LOADING = '@MACHINE_SHOP/MACHINE_SHOP_API_LOADING'
export const MACHINE_SHOP_API_ERROR = '@MACHINE_SHOP/MACHINE_SHOP_API_ERROR'
export const SUCCESS_CREATE_MACHINE_SHOP = '@MACHINE_SHOP/SUCCESS_CREATE_MACHINE_SHOP'
export const AUTH_SUCCESS_CREATE_MACHINE_SHOP = '@MACHINE_SHOP/AUTH_SUCCESS_CREATE_MACHINE_SHOP'
export const SUCCESS_UPDATE_MACHINE_SHOP = '@MACHINE_SHOP/SUCCESS_UPDATE_MACHINE_SHOP'
export const SUCCESS_MACHINE_SHOP_CAPABILITY_LIST = '@MACHINE_SHOP/SUCCESS_MACHINE_SHOP_CAPABILITY_LIST'
export const SUCCESS_MACHINE_SHOP_CERTIFICATION_LIST = '@MACHINE_SHOP/SUCCESS_MACHINE_SHOP_CERTIFICATION_LIST'
export const SUCCESS_ASSIGN_MACHINE_SHOP_ID = '@MACHINE_SHOP/SUCCESS_ASSIGN_MACHINE_SHOP_ID'
export const SUCCESS_GET_MACHINE_SHOPS = '@MACHINE/SUCCESS_GET_MACHINE_SHOPS'
export const SET_SELECTED_MACHINE_SHOP = '@MACHINE/SET_SELECTED_MACHINE_SHOP'
export const SUCCESS_APPROVE_MACHINE_SHOP = '@MACHINE/SUCCESS_APPROVE_MACHINE_SHOP'
export const SET_APPLICATION_DATA = '@MACHINE/SET_APPLICATION_DATA'
export const SUCCESS_GET_ADDRESS = '@MACHINE/SUCCESS_GET_ADDRESS'
export const SUCCESS_GET_MACHINE_SHOP = '@MACHINE/SUCCESS_GET_MACHINE_SHOP'
export const SUCCESS_BANK_CONNECT = '@MACHINE/SUCCESS_BANK_CONNECT'
export const SUCCESS_CHECK_STATUS_BANK_CONNECTION = '@MACHINE/SUCCESS_CHECK_STATUS_BANK_CONNECTION'
export const SUCCESS_BANK_DISCONNECT = '@MACHINE/SUCCESS_BANK_DISCONNECT'
export const SUCCESS_GET_MACHINE_SHOP_STATUS = '@MACHINE/SUCCESS_GET_MACHINE_SHOP_STATUS'

// part action types
export const PART_API_LOADING = '@PART/PART_API_LOADING'
export const PART_API_ERROR = '@PART/PART_API_ERROR'
export const SUCCESS_CREATE_PART = '@PART/SUCCESS_CREATE_PART'
export const SUCCESS_GET_PARTS = '@PART/SUCCESS_GET_PARTS'
export const SUCCESS_GET_FILE = '@PART/SUCCESS_GET_FILE'
export const SUCCESS_GET_PART = '@PART/SUCCESS_GET_PART'
export const SUCCESS_UPDATE_PART = '@PART/SUCCESS_UPDATE_PART'
export const SUCCESS_DELETE_PART = '@PART/SUCCESS_DELETE_PART'

// part request action types
export const PART_REQUEST_API_LOADING = '@PART_REQUEST/PART_REQUEST_API_LOADING'
export const PART_REQUEST_API_ERROR = '@PART_REQUEST/PART_REQUEST_API_ERROR'
export const CLEAR_PART_REQUEST = '@PART_REQUEST/CLEAR_PART_REQUEST'
export const SUCCESS_CREATE_PART_REQUEST = '@PART_REQUEST/SUCCESS_CREATE_PART_REQUEST'
export const SUCCESS_GET_PART_REQUESTS = '@PART_REQUEST/SUCCESS_GET_PART_REQUESTS'
export const SUCCESS_GET_PART_REQUEST_DETAIL = '@PART/SUCCESS_GET_PART_REQUEST_DETAIL'
export const SUCCESS_GET_PART_REQUEST = '@PART_REQUEST/SUCCESS_GET_PART_REQUEST'
export const SUCCESS_ACKNOWLEDGE_QUOTE_REQUEST = '@PART_REQUEST/SUCCESS_ACKNOWLEDGE_QUOTE_REQUEST'
export const SUCCESS_CREATE_PART_BID = '@PART_REQUEST/SUCCESS_CREATE_PART_BID'
export const SUCCESS_GET_PROJECT_PART_REQUESTS = '@PART_REQUEST/SUCCESS_GET_PROJECT_PART_REQUESTS'
export const SUCCESS_GET_QUOTE_PART_REQUESTS = '@PART_REQUEST/SUCCESS_GET_QUOTE_PART_REQUESTS'
export const SUCCESS_UPDATE_PART_REQUEST = '@PART_REQUEST/SUCCESS_UPDATE_PART_REQUEST'
export const SUCCESS_DELETE_PART_REQUEST = '@PART_REQUEST/SUCCESS_DELETE_PART_REQUEST'
export const SUCCESS_DOWNLOAD_FILE = '@PART_REQUEST/SUCCESS_DOWNLOAD_FILE'
export const TOGGLE_PART_REQUESTS = '@PART_REQUEST/TOGGLE_PART_REQUESTS'

// project action types
export const PROJECT_API_LOADING = '@PROJECT/PROJECT_API_LOADING'
export const PROJECT_API_ERROR = '@PROJECT/PROJECT_API_ERROR'
export const SUCCESS_CREATE_PROJECT = '@PROJECT/SUCCESS_CREATE_PROJECT'
export const SUCCESS_GET_PROJECTS = '@PROJECT/SUCCESS_GET_PROJECTS'
export const SUCCESS_GET_PROJECT = '@PROJECT/SUCCESS_GET_PROJECT'
export const SUCCESS_DELETE_PROJECT = '@PROJECT/SUCCESS_DELETE_PROJECT'

// quote action types
export const QUOTE_API_LOADING = '@QUOTE/QUOTE_API_LOADING'
export const QUOTE_API_ERROR = '@QUOTE/QUOTE_API_ERROR'
export const SUCCESS_CREATE_QUOTE = '@QUOTE/SUCCESS_CREATE_QUOTE'
export const SUCCESS_GET_QUOTES = '@QUOTE/SUCCESS_GET_QUOTES'
export const SUCCESS_GET_QUOTE_REQUESTS = '@QUOTE/SUCCESS_GET_QUOTE_REQUESTS'
export const SUCCESS_GET_QUOTE = '@QUOTE/SUCCESS_GET_QUOTE'
export const SUCCESS_UPDATE_QUOTE = '@QUOTE/SUCCESS_UPDATE_QUOTE'
export const SUCCESS_SEND_QUOTE = '@QUOTE/SUCCESS_SEND_QUOTE'
export const SUCCESS_UPLOAD_FILE = '@QUOTE/SUCCESS_UPLOAD_FILE'

// shop action types
export const SHOP_API_LOADING = '@SHOP/SHOP_API_LOADING'
export const SHOP_API_ERROR = '@SHOP/SHOP_API_ERROR'
export const SUCCESS_CREATE_SHOP = '@SHOP/SUCCESS_CREATE_SHOP'
export const SUCCESS_GET_SHOPS = '@SHOP/SUCCESS_GET_SHOPS'
export const SUCCESS_GET_ADDRESS_FOR_SHOP = '@SHOP/SUCCESS_GET_ADDRESS_FOR_SHOP'
export const SUCCESS_GET_SHOP = '@SHOP/SUCCESS_GET_SHOP'
export const SUCCESS_UPDATE_SHOP = '@SHOP/SUCCESS_UPDATE_SHOP'
export const SUCCESS_DELETE_SHOP = '@SHOP/SUCCESS_DELETE_SHOP'
export const SET_SELECTED_SHOP = '@SHOP/SET_SELECTED_SHOP'
export const CLEAR_SELECTED_SHOP = '@SHOP/CLEAR_SELECTED_SHOP'
export const SET_SHOP_FILTERS_BY_SERVICE = '@SHOP/SET_SHOP_FILTERS_BY_SERVICE'
export const SET_SHOP_FILTERS_BY_SERVICE_2 = '@SHOP/SET_SHOP_FILTERS_BY_SERVICE_2'

// cart action types
export const CART_LOADING = '@CART/CART_LOADING'
export const CART_ERROR = '@CART/CART_ERROR'
export const SUCCESS_GET_CARTS = '@CART/SUCCESS_GET_CARTS'
export const SUCCESS_GET_PART_REQUEST_FOR_CART = '@CART/SUCCESS_GET_PART_REQUEST_FOR_CART'
export const SUCCESS_CREATE_CART = '@CART/SUCCESS_CREATE_CART'
export const SUCCESS_SHARE_CART = '@CART/SUCCESS_SHARE_CART'
export const SUCCESS_DELETE_PART_REQUEST_FROM_CART = '@CART/SUCCESS_DELETE_PART_REQUEST_FROM_CART'
export const SUCCESS_CREATE_STRIPE = '@CART/SUCCESS_CREATE_STRIPE'
export const SUCCESS_CHECKOUT_SESSION = '@CART/SUCCESS_CHECKOUT_SESSION'
export const SUCCESS_NOTIFY_CHECKOUT_STATUS = '@CART/SUCCESS_NOTIFY_CHECKOUT_STATUS'
export const SUCCESS_COMPLETE_ORDER = '@CART/SUCCESS_COMPLETE_ORDER'
export const SUCCESS_ACKNOWLEDGE_ORDER = '@CART/SUCCESS_ACKNOWLEDGE_ORDER'
export const SUCCESS_CREATE_PURCHASE_ORDER = '@CART/SUCCESS_CREATE_PURCHASE_ORDER'
export const SUCCESS_ADMIN_APPROVE_PO = '@CART/SUCCESS_ADMIN_APPROVE_PO'
export const SUCCESS_APPROVE_PO = '@CART/SUCCESS_APPROVE_PO'
export const SUCCESS_MACHINIST_CANCEL_ORDER = '@CART/SUCCESS_MACHINIST_CANCEL_ORDER'
export const SUCCESS_GET_ALL_PAYMENTS = '@CART/SUCCESS_GET_ALL_PAYMENTS'
export const SUCCESS_UPDATE_TRACKING_NUMBER = '@CART/SUCCESS_UPDATE_TRACKING_NUMBER'

// conversation action types
export const CONVERSATION_API_LOADING = '@CONVERSATION/CONVERSATION_LOADING'
export const SUCCESS_GET_CONVERSATION = '@CONVERSATION/SUCCESS_GET_CONVERSATIONS'
export const CONVERSATION_API_ERROR = '@SHOP/CONVERSATION_API_ERROR'

// help action types
export const HELP_API_LOADING = '@HELP/HELP_API_LOADING'
export const SUCCESS_CREATE_HELP = '@HELP/SUCCESS_CREATE_HELP'
