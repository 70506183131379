import { combineReducers } from 'redux'

import auth from './auth'
import company from './company'
import machineShop from './machineShop'
import part from './part'
import partRequest from './partRequest'
import project from './project'
import quote from './quote'
import shop from './shop'
import cart from './cart'
import help from './help'

export default combineReducers({
  auth,
  company,
  machineShop,
  part,
  partRequest,
  project,
  quote,
  shop,
  cart,
  help
})